import { Column } from 'react-table';
import { OrganizationUser } from '@/features/iam/types';
import { displayDateTime, displayUserStatusName } from '@/utils/display-utils';

const columns: Column<OrganizationUser>[] = [
  {
    Header: 'First name',
    accessor: 'firstName',
  },
  {
    Header: 'Last name',
    accessor: 'lastName',
  },
  {
    Header: 'Roles',
    accessor: (row) => row.roles?.map((r) => r.name).join(', ') ?? 'No role',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Organization access',
    accessor: (row) => displayUserStatusName(row.status),
  },
  {
    Header: 'Application access',
    accessor: (row) => displayUserStatusName(row.userStatus),
  },
  {
    Header: 'Last sign in',
    accessor: (row) =>
      row.lastSignInDate == null
        ? 'N/A'
        : displayDateTime(new Date(row.lastSignInDate)),
  },
];

export default columns;
