import { ReactNode, createContext, useContext } from 'react';
import { GlobalConfig } from '../types';
import { AwardType } from '@/features/awards/types';
import { DocumentVariant } from '@/features/documents/types';

const ConfigContext = createContext({} as GlobalConfig);

export function ConfigProvider({
  config,
  children,
}: {
  config: GlobalConfig;
  children: ReactNode;
}) {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
}

function useConfig(): GlobalConfig {
  return useContext(ConfigContext);
}

function useGrantConfig(awardType: AwardType) {
  return useConfig().grants.find((g) => g.awardType === awardType);
}

function useGrantAwardDefinitionConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  return useGrantConfig(awardType)?.awardDefinitions?.find(
    (d) => d.version === awardDefinitionVersion
  );
}

function useGlobalSpendPlanConfig() {
  return useConfig().spendPlanConfiguration;
}

function useGlobalWorkPlanConfig() {
  return useConfig().workPlanConfiguration;
}

function useGlobalCpgConfig() {
  return useConfig().cpgConfiguration;
}

function useGrantAwardDefinitionCpgConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  return useGrantAwardDefinitionConfig(awardType, awardDefinitionVersion)
    ?.cpgConfiguration;
}

function useGrantAwardDefinitionSpendPlanConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  return useGrantAwardDefinitionConfig(awardType, awardDefinitionVersion)
    ?.spendPlanConfiguration;
}

function useGrantAwardDefinitionWorkPlanConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  return useGrantAwardDefinitionConfig(awardType, awardDefinitionVersion)
    ?.workPlanConfiguration;
}

function useGrantSpendPlanConfig(awardType: AwardType) {
  return useGrantConfig(awardType)?.spendPlanConfiguration;
}

function useGrantCpgConfig(awardType: AwardType) {
  return useGrantConfig(awardType)?.cpgConfiguration;
}

function useGrantWorkPlanConfig(awardType: AwardType) {
  return useGrantConfig(awardType)?.workPlanConfiguration;
}

export function useVisibleSpendPlanPersonnelFields(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionSpendPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.visiblePersonnelFields;
  const grant = useGrantSpendPlanConfig(awardType)?.visiblePersonnelFields;
  const global = useGlobalSpendPlanConfig()?.visiblePersonnelFields;

  return definitions ?? grant ?? global ?? [];
}

export function useVisibleWorkPlanPlannedActivityFields(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionWorkPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.visiblePlannedActivityFields;
  const grant = useGrantWorkPlanConfig(awardType)?.visiblePlannedActivityFields;
  const global = useGlobalWorkPlanConfig()?.visiblePlannedActivityFields;

  return definitions ?? grant ?? global ?? [];
}

export function usePossibleIssueAreasConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionWorkPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.possibleIssueAreas;
  const grant = useGrantWorkPlanConfig(awardType)?.possibleIssueAreas;
  const global = useGlobalWorkPlanConfig()?.possibleIssueAreas;

  return definitions ?? grant ?? global ?? [];
}

export function usePossibleStrategyAreasConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionWorkPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.possibleStrategyAreas;
  const grant = useGrantWorkPlanConfig(awardType)?.possibleStrategyAreas;
  const global = useGlobalWorkPlanConfig()?.possibleStrategyAreas;

  return definitions ?? grant ?? global ?? [];
}

export function usePossibleProgramAreasConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionConfig(
    awardType,
    awardDefinitionVersion
  )?.possibleProgramAreas;
  const grant = useGrantConfig(awardType)?.possibleProgramAreas;
  const global = useConfig()?.possibleProgramAreas;

  return definitions ?? grant ?? global ?? [];
}

export function useProgressUpdateLabelsConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionWorkPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.progressUpdateLabels;
  const grant = useGrantWorkPlanConfig(awardType)?.progressUpdateLabels;
  const global = useGlobalWorkPlanConfig()?.progressUpdateLabels;

  return definitions ?? grant ?? global ?? [];
}

export function usePossibleJobClassificationCategoriesConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionSpendPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.possibleJobClassificationCategories;
  const grant =
    useGrantSpendPlanConfig(awardType)?.possibleJobClassificationCategories;
  const global =
    useGlobalSpendPlanConfig()?.possibleJobClassificationCategories;

  return definitions ?? grant ?? global ?? [];
}

export function usePossibleProgressStatusesConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionWorkPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.possibleProgressStatuses;
  const grant = useGrantWorkPlanConfig(awardType)?.possibleProgressStatuses;
  const global = useGlobalWorkPlanConfig()?.possibleProgressStatuses;

  return definitions ?? grant ?? global ?? [];
}

export function usePossibleServiceTypesConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionSpendPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.possibleServiceTypes;
  const grant = useGrantSpendPlanConfig(awardType)?.possibleServiceTypes;
  const global = useGlobalSpendPlanConfig()?.possibleServiceTypes;

  return definitions ?? grant ?? global ?? [];
}

export function usePossibleIncentiveTypesConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionSpendPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.possibleIncentiveTypes;
  const grant = useGrantSpendPlanConfig(awardType)?.possibleIncentiveTypes;
  const global = useGlobalSpendPlanConfig()?.possibleIncentiveTypes;

  return definitions ?? grant ?? global ?? [];
}

export function useDisplayExpenditureReportConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionSpendPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.displayExpenditureReport;
  const grant = useGrantSpendPlanConfig(awardType)?.displayExpenditureReport;
  const global = useGlobalSpendPlanConfig()?.displayExpenditureReport;

  return definitions ?? grant ?? global ?? false;
}

export function useListViewHideBudgetPeriodConfig(awardType: AwardType) {
  const config = useGrantConfig(awardType);

  const definitions =
    config?.awardDefinitions == null ||
    config.awardDefinitions.every((d) => d.hideBudgetPeriod == null)
      ? null
      : config?.awardDefinitions?.every((d) => d.hideBudgetPeriod === true);

  const grant = config?.hideBudgetPeriod;
  const global = useConfig()?.hideBudgetPeriod;

  return definitions ?? grant ?? global ?? false;
}

export function useGeneralListViewHideBudgetPeriodConfig(
  awardTypes: AwardType[]
) {
  const config = useConfig();

  const awardConfigs = awardTypes.map((awardType) =>
    config.grants.find((g) => g.awardType === awardType)
  );

  return awardConfigs.every((awardConfig) => {
    const definitions =
      awardConfig?.awardDefinitions == null ||
      awardConfig.awardDefinitions.every((d) => d.hideBudgetPeriod == null)
        ? null
        : awardConfig?.awardDefinitions?.every(
            (d) => d.hideBudgetPeriod === true
          );
    const grant = awardConfig?.hideBudgetPeriod;
    const global = config?.hideBudgetPeriod;

    return definitions ?? grant ?? global ?? false;
  });
}

export function useHideBudgetPeriodConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionConfig(
    awardType,
    awardDefinitionVersion
  )?.hideBudgetPeriod;
  const grant = useGrantConfig(awardType)?.hideBudgetPeriod;
  const global = useConfig()?.hideBudgetPeriod;

  return definitions ?? grant ?? global ?? false;
}

export function useAttachEmptyKnownGapConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionConfig(
    awardType,
    awardDefinitionVersion
  )?.attachEmptyKnownGap;
  const grant = useGrantConfig(awardType)?.attachEmptyKnownGap;
  const global = useConfig()?.attachEmptyKnownGap;

  return definitions ?? grant ?? global ?? true;
}

export function useInvoiceRulesConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionSpendPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.invoiceRules;
  const grant = useGrantSpendPlanConfig(awardType)?.invoiceRules;
  const global = useGlobalSpendPlanConfig()?.invoiceRules;

  return definitions ?? grant ?? global ?? [];
}

export function useBudgetRulesConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionSpendPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.budgetRules;
  const grant = useGrantSpendPlanConfig(awardType)?.budgetRules;
  const global = useGlobalSpendPlanConfig()?.budgetRules;

  return definitions ?? grant ?? global ?? [];
}

export function useExpenditureReportRulesConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionSpendPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.expenditureReportRules;
  const grant = useGrantSpendPlanConfig(awardType)?.expenditureReportRules;
  const global = useGlobalSpendPlanConfig()?.expenditureReportRules;

  return definitions ?? grant ?? global ?? [];
}

export function useExpenditureReportCardConfigs(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionSpendPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.expenditureReportCards;
  const grant = useGrantSpendPlanConfig(awardType)?.expenditureReportCards;
  const global = useGlobalSpendPlanConfig()?.expenditureReportCards;

  return definitions ?? grant ?? global ?? [];
}

export function useCpgPossibleSubmissionReasonsConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionCpgConfig(
    awardType,
    awardDefinitionVersion
  )?.possibleSubmissionReasons;
  const grant = useGrantCpgConfig(awardType)?.possibleSubmissionReasons;
  const global = useGlobalCpgConfig()?.possibleSubmissionReasons;

  return definitions ?? grant ?? global ?? [];
}

export function useWorkPlanPossibleSubmissionReasonsConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionWorkPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.possibleSubmissionReasons;
  const grant = useGrantWorkPlanConfig(awardType)?.possibleSubmissionReasons;
  const global = useGlobalWorkPlanConfig()?.possibleSubmissionReasons;

  return definitions ?? grant ?? global ?? [];
}

export function useSpendPlanPossibleSubmissionReasonsConfig(
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const definitions = useGrantAwardDefinitionSpendPlanConfig(
    awardType,
    awardDefinitionVersion
  )?.possibleSubmissionReasons;
  const grant = useGrantSpendPlanConfig(awardType)?.possibleSubmissionReasons;
  const global = useGlobalSpendPlanConfig()?.possibleSubmissionReasons;

  return definitions ?? grant ?? global ?? [];
}

export function useDocumentPossibleSubmissionReasonsConfig(
  documentVariant: DocumentVariant,
  awardType: AwardType,
  awardDefinitionVersion: number
) {
  const cpgConfig = useCpgPossibleSubmissionReasonsConfig(
    awardType,
    awardDefinitionVersion
  );
  const workPlanConfig = useWorkPlanPossibleSubmissionReasonsConfig(
    awardType,
    awardDefinitionVersion
  );
  const spendPlanConfig = useSpendPlanPossibleSubmissionReasonsConfig(
    awardType,
    awardDefinitionVersion
  );

  switch (documentVariant) {
    case 'Planning Guide':
      return cpgConfig ?? [];
    case 'Work Plan':
      return workPlanConfig ?? [];
    case 'Spend Plan':
      return spendPlanConfig ?? [];
  }
}
