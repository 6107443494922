/* eslint-disable react/jsx-key */
import {
  Column,
  HeaderGroup,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { Link } from 'react-router-dom';
import { getDefaultColumn } from './DefaultColumnFilter';
import { sortTypes } from '@/components/table/Sortable';
import Pagination from './Pagination';
import { ReactNode } from 'react';
import Checkbox from '../inputs/Checkbox';

interface SelectionProps<T extends object> {
  selectedRows: T[];
  onSelect: (selectedRows: T[]) => void;
}

interface Props<T extends object> {
  data: T[];
  columns: Column<T>[];
  filterHidden?: (column: any) => boolean;
  onClick?: (value: T) => null | (() => void) | 'NOTHING';
  highlight?: (value: T) => boolean;
  selection?: SelectionProps<T>;
}

const useCommonTable = <T extends object>({
  data,
  columns,
  filterHidden = () => true,
  onClick = () => null,
  highlight = () => false,
  selection,
}: Props<T>) => {
  function RowBuilder({ row, cell }: { row: Row<T>; cell: any }) {
    const baseStyles = 'cursor-pointer w-full h-full block px-4 py-3';

    const onClickAction = onClick(row.original);

    if (onClickAction === 'NOTHING') {
      return (
        <div {...cell.getCellProps()} className="px-4 py-2.5">
          {cell.render('Cell')}
        </div>
      );
      // eslint-disable-next-line @typescript-eslint/dot-notation
    } else if (onClickAction === null && row.original['id']) {
      return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <Link className={baseStyles} to={row.original['id'].toString()}>
          {cell.render('Cell')}
        </Link>
      );
    } else if (typeof onClickAction === 'function') {
      return (
        <div
          onClick={() => onClickAction()}
          className={`${baseStyles} px-4 py-2.5`}
        >
          {cell.render('Cell')}
        </div>
      );
    }

    return null;
  }

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageIndex },
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      sortTypes,
      defaultColumn: getDefaultColumn<T>(),
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const selectable = selection != null;
  const selectedData = selection?.selectedRows ?? [];
  const setSelectedData = selection?.onSelect ?? (() => {});
  const isSelectAll = data.every((d) => selectedData.includes(d));

  function onSelect(entry: T) {
    const newData = selectedData.includes(entry)
      ? selectedData.filter((d) => d !== entry)
      : [...selectedData, entry];

    setSelectedData(newData);
  }

  function onSelectAll() {
    const newData = isSelectAll ? [] : [...data];

    setSelectedData(newData);
  }

  const headerClass =
    'text-xs bg-grey-50 bg-pale-grey py-3 pl-4 text-left font-medium tracking-wider text-grey-30';
  const rowClass = 'border-y border-solid border-light-grey';
  const cellClass = 'text-xs font-semibold text-grey-black';

  return {
    table: (
      <table className="grant-table min-w-full table-auto rounded-lg bg-white">
        <thead className="table-head">
          {headerGroups.map((hG: HeaderGroup<T>) => (
            <tr
              className="table-head-row sticky top-0 whitespace-nowrap"
              {...hG.getHeaderGroupProps()}
            >
              <>
                {selectable && (
                  <th scope="col" className={headerClass} title="Select">
                    <Checkbox
                      id={`Select all checkbox`}
                      isChecked={isSelectAll}
                      onAnswer={() => onSelectAll()}
                    />
                  </th>
                )}
                {hG.headers
                  .filter((hG) => filterHidden(hG.Header))
                  .map((col: HeaderGroup<T>) => (
                    <th
                      scope="col"
                      className={headerClass}
                      {...col.getHeaderProps(col.getSortByToggleProps())}
                      title={`${col.canSort ? 'Sort by' : ''} ${col.Header}`}
                    >
                      {col.render('Header')}
                      {col.canSort && (
                        <span>
                          {col.isSorted ? (
                            col.isSortedDesc ? (
                              <span className="arrow-drop-up" />
                            ) : (
                              <span className="arrow-drop-down" />
                            )
                          ) : (
                            <span className="arrow-right" />
                          )}
                        </span>
                      )}
                    </th>
                  ))}
              </>
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="table-body whitespace-nowrap rounded-lg text-grey-black"
        >
          {page.map((row: Row<T>, index) => {
            prepareRow(row);

            const isSelected = selectedData.includes(row.original);
            const isHighlighted = highlight(row.original);
            const backgroundColor = isSelected
              ? 'bg-purple-40'
              : isHighlighted
                ? 'bg-orange-50'
                : 'bg-white';

            const hover = isHighlighted
              ? 'hover:bg-orange'
              : 'hover:bg-purple-50';

            return (
              <tr
                className={`${rowClass} ${hover} ${backgroundColor}`}
                {...row.getRowProps()}
              >
                <>
                  {selectable && (
                    <td className={`${cellClass} px-4 py-2.5`}>
                      <Checkbox
                        id={`${index}`}
                        isChecked={selectedData.includes(row.original)}
                        onAnswer={() => onSelect(row.original)}
                      />
                    </td>
                  )}
                  {row.cells
                    .filter((hG) => filterHidden(hG.column.Header))
                    .map((cell: any) => (
                      <td {...cell.getCellProps()} className={cellClass}>
                        <RowBuilder cell={cell} row={row} />
                      </td>
                    ))}
                </>
              </tr>
            );
          })}
        </tbody>
      </table>
    ),
    pagination: (
      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        nextPage={nextPage}
        previousPage={previousPage}
        pageIndex={pageIndex}
      />
    ),
    functionalHeaderGetter: (
      customFilters?: ReactNode,
      customActions?: ReactNode
    ) => (
      <>
        <div className="relative flex w-full justify-between gap-4 self-center rounded-lg bg-white p-3.5">
          <div className="flex flex-wrap gap-4">
            {headerGroups.map((hG: HeaderGroup<T>, index: number) => (
              <div key={index}>
                <div className="flex gap-4" {...hG.getHeaderGroupProps()}>
                  {hG.headers.map((col: HeaderGroup<T>, index: number) =>
                    col.disableFilters ? null : (
                      <div
                        key={index}
                        className="text-xs divide-y-1 text-left font-medium tracking-wider"
                      >
                        {col.canFilter ? (
                          <div className="rounded-lg border border-light-grey bg-white">
                            {col.render('Filter')}
                          </div>
                        ) : null}
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
            {customFilters}
          </div>
        </div>
        {customActions}
      </>
    ),
    setPageSize,
  };
};

export default useCommonTable;
