import { ReactNode } from 'react';
import clsx from 'clsx';
import { ModalController } from '@/components/modals/useModalController';

interface Props {
  controller: ModalController;
  children: ReactNode;
  preventClosingOnBarrierTap?: boolean;
  onBarrierTap?: () => void;
  header?: string;
}

export default function Modal({
  controller,
  children,
  preventClosingOnBarrierTap = false,
  onBarrierTap,
  header,
}: Props) {
  return (
    <div
      className={clsx(
        'fixed inset-0 z-[10] flex items-center',
        !controller.isOpen && 'hidden'
      )}
    >
      <div className="container relative z-[2] m-auto my-8 flex max-h-[calc(100dvh-4rem)] max-w-fit flex-col overflow-auto rounded-lg border border-light-grey bg-white p-5 shadow-lg">
        {header && <h1 className="mb-1 text-base-l font-semibold">{header}</h1>}
        {children}
      </div>
      <div
        className="absolute inset-0 z-[1] w-full bg-black opacity-30"
        onClick={(e) => {
          e.stopPropagation();
          onBarrierTap?.();
          if (!preventClosingOnBarrierTap) {
            controller.close();
          }
        }}
      />
    </div>
  );
}
