import axios from 'axios';
import { FileStorageEntry, FileStorageResponse } from '../types';

export class FileStorageService {
  async getFileStorage(): Promise<FileStorageResponse> {
    const response = await axios.get('/i_nodes');

    return response.data;
  }

  async getFileStorageEntry(entryId: string): Promise<FileStorageEntry> {
    const response = await axios.get(`/i_nodes/${entryId}`);

    return response.data;
  }

  async createDirectory(
    parentFolderId: number,
    folderName: string,
    allowLhjFileUpload: boolean,
    tags: string[]
  ): Promise<FileStorageEntry> {
    const response = await axios.post(`/i_nodes?parent_id=${parentFolderId}`, {
      i_node: {
        name: folderName,
        parent_id: parentFolderId,
        allowLhjFileUpload,
        tags,
      },
    });

    return response.data;
  }

  async createFile(
    parentFolderId: number,
    file: File
  ): Promise<FileStorageEntry> {
    const formData = new FormData();

    formData.append('i_node[file]', file);
    formData.append('i_node[parent_id]', parentFolderId.toString());

    const response = await axios.post(
      `/i_nodes?parent_id=${parentFolderId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }

  async deleteEntry(entryId: number): Promise<void> {
    const response = await axios.delete(`/i_nodes/${entryId}`);

    return response.data;
  }

  async editEntry(
    entryId: number,
    newName: string,
    allowLhjFileUpload?: boolean,
    tags?: string[]
  ): Promise<FileStorageEntry> {
    const response = await axios.put(`/i_nodes/${entryId}`, {
      i_node: { name: newName, allowLhjFileUpload, tags },
    });

    return response.data;
  }

  async getExport(folderId: number): Promise<string> {
    const response = await axios.get(`/i_nodes/${folderId}/export`);

    return response.data;
  }

  async addEntryComment(
    entryId: number,
    content: string
  ): Promise<FileStorageEntry> {
    const response = await axios.post(`/i_nodes/${entryId}/comments/`, {
      comment: { content },
    });

    return response.data;
  }
}
