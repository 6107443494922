import {
  OrganizationDetailed,
  OrganizationUsersResponse,
} from '@/features/iam/types';
import OrganizationUsersTable from '@/features/iam/pages/organization-users-table/components/table';
import AddUserButton from '@/features/iam/pages/organization-users-table/components/AddUserButton';
import Button from '@/components/Button';
import { useState } from 'react';
import DownloadOrganizationUsersButton from './components/DownloadOrganizationUsersButton';

const Content = ({
  organizationUsersResponse,
  refetchOrganizationsUsers,
  organizationResponse,
}: {
  organizationUsersResponse: OrganizationUsersResponse;
  refetchOrganizationsUsers: () => void;
  organizationResponse: OrganizationDetailed;
}) => {
  const [filter, setFilter] = useState<'active' | 'inactive'>('active');

  const permissions = organizationUsersResponse.permissions.create;
  const possibleRolesToCreate = permissions.roles;
  const availableRegions = permissions.regions;

  const filteredUsers = organizationUsersResponse.data.filter((user) => {
    return filter === 'inactive'
      ? user.status === 'inactive' || user.userStatus === 'inactive'
      : user.status !== 'inactive' && user.userStatus !== 'inactive';
  });

  return (
    <div className="organization-users">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-4">
          <Button
            type="text"
            textColor={filter === 'active' ? 'text-grey-black' : 'text-grey-30'}
            onClick={() => setFilter('active')}
          >
            Active users
          </Button>
          <Button
            type="text"
            textColor={
              filter === 'inactive' ? 'text-grey-black' : 'text-grey-30'
            }
            onClick={() => setFilter('inactive')}
          >
            Inactive users
          </Button>
        </div>

        <div className="mb-4 flex">
          <DownloadOrganizationUsersButton
            organizationId={organizationResponse.id}
            organizationType={organizationResponse.type}
          />
          {possibleRolesToCreate.length > 0 && (
            <>
              <div className="w-5" />
              <AddUserButton
                possibleRoles={possibleRolesToCreate}
                availableRegions={availableRegions}
                refetchOrganizationsUsers={refetchOrganizationsUsers}
                organizationType={organizationResponse.type}
                canSetAdmin={
                  organizationUsersResponse.permissions.create.canSetAdmin
                }
              />
            </>
          )}
        </div>
      </div>
      <OrganizationUsersTable
        key="organization-users-table"
        organizationUsers={filteredUsers}
      />
    </div>
  );
};

export default Content;
