export const DAYS_REMAINING = 10;

export const TABLE_DEFAULT_ROW_SIZE = 7;
export const TABLE_MARGIN_SIZE = 420;
export const ROW_HEIGHT = 39;

export const CARD_MAX_HEIGHT = 270;

export const REVIEW_SUMMARY_DATA_KEY = 'REVIEW_SUMMARY_DATA';

export const REVIEW_SUMMARY_HEADER_ID = 'REVIEW_SUMMARY_HEADER';
export const REVIEW_SUMMARY_DOCUMENT_COMMENT_ID =
  'DOCUMENT_COMMENTS_REVIEW_SUMMARY';

export const REQUEST_FOR_UPDATE_DATA_KEY = 'REQUEST_FOR_UPDATE_DATA';

export const CABLE_NOTIFICATIONS_CHANNEL_KEY = 'NotificationsChannel';
export const CABLE_REPORTS_CHANNEL_KEY = 'ReportsChannel';

export const FUNDING_SOURCE_ID = 'funding-source-id';
