const Logo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="408.000000pt"
    height="65.000000pt"
    viewBox="0 0 408.000000 65.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,65.000000) scale(0.100000,-0.100000)"
      fill="#FFF"
      stroke="none"
    >
      <path
        d="M212 634 c-12 -8 -22 -26 -22 -39 0 -23 32 -55 57 -55 19 0 43 30 43
  55 0 20 -32 55 -50 55 -3 0 -16 -7 -28 -16z"
      />
      <path
        d="M320 602 c0 -11 110 -36 136 -30 10 3 -8 10 -41 17 -33 7 -68 15 -77
  17 -10 3 -18 1 -18 -4z"
      />
      <path
        d="M502 578 c-7 -7 -12 -24 -12 -38 0 -33 17 -50 50 -50 34 0 50 17 50
  52 0 41 -59 65 -88 36z"
      />
      <path
        d="M130 495 c-24 -25 -38 -45 -30 -44 16 1 98 89 83 89 -5 0 -29 -20
  -53 -45z"
      />
      <path
        d="M270 517 c0 -13 63 -127 70 -127 12 0 -47 124 -60 128 -5 2 -10 2
  -10 -1z"
      />
      <path
        d="M3650 435 l0 -66 -32 17 c-57 29 -137 5 -175 -53 -23 -35 -23 -121 0
  -157 39 -59 117 -82 177 -51 25 13 29 13 34 -1 4 -10 16 -14 38 -12 l33 3 3
  193 2 192 -40 0 -40 0 0 -65z m-17 -127 c34 -40 17 -111 -30 -129 -48 -18
  -103 23 -103 76 0 72 87 107 133 53z"
      />
      <path
        d="M964 480 c-88 -20 -144 -91 -144 -179 0 -91 44 -154 126 -181 46 -15
  140 -8 182 14 32 16 32 17 32 91 l0 75 -55 0 -55 0 0 -35 c0 -28 4 -35 20 -35
  13 0 20 -7 20 -19 0 -23 -19 -31 -77 -31 -38 0 -50 5 -79 34 -28 28 -34 42
  -34 75 0 56 22 95 64 115 45 21 73 20 121 -4 l39 -20 24 24 24 23 -30 21 c-28
  20 -100 42 -132 41 -8 -1 -29 -4 -46 -9z"
      />
      <path
        d="M451 438 c-17 -23 -31 -47 -31 -52 1 -15 70 66 70 82 0 19 -6 15 -39
  -30z"
      />
      <path
        d="M2400 295 l0 -185 40 0 40 0 0 55 0 55 39 0 c35 0 42 -5 80 -55 38
  -51 45 -55 82 -55 45 0 45 -3 -10 74 -30 42 -32 49 -18 57 69 39 82 143 25
  197 -37 33 -73 42 -179 42 l-99 0 0 -185z m211 99 c23 -21 25 -77 3 -95 -9 -7
  -42 -15 -75 -17 l-59 -4 0 64 c0 36 3 68 8 72 12 13 103 -2 123 -20z"
      />
      <path
        d="M2170 425 c0 -28 -4 -35 -20 -35 -16 0 -20 -7 -20 -30 0 -23 5 -30
  19 -30 17 0 19 -9 22 -85 5 -108 22 -130 103 -130 30 0 57 3 60 6 4 3 2 18 -3
  32 -7 19 -15 24 -31 20 -37 -10 -50 14 -50 88 l0 69 35 0 c32 0 35 2 35 30 0
  28 -3 30 -35 30 -33 0 -35 2 -35 35 0 34 -1 35 -40 35 -39 0 -40 -1 -40 -35z"
      />
      <path
        d="M17 429 c-21 -12 -23 -62 -3 -78 46 -38 111 25 71 69 -20 22 -41 25
  -68 9z"
      />
      <path
        d="M1355 384 c-19 -10 -31 -11 -33 -5 -2 6 -21 11 -43 11 l-39 0 0 -140
  0 -140 40 0 40 0 0 85 c0 78 2 88 25 110 13 14 33 25 45 25 16 0 20 6 20 35 0
  39 -10 43 -55 19z"
      />
      <path
        d="M1505 385 c-22 -8 -41 -15 -42 -15 -2 0 3 -12 9 -26 9 -19 17 -24 32
  -19 62 18 94 17 115 -4 31 -31 26 -41 -21 -41 -124 0 -185 -66 -125 -135 28
  -33 92 -44 135 -22 27 13 31 13 36 0 4 -9 17 -13 38 -11 l33 3 0 101 c0 67 -5
  110 -14 127 -28 53 -115 71 -196 42z m135 -175 c0 -42 -80 -65 -103 -29 -20
  32 -1 49 53 49 43 0 50 -3 50 -20z"
      />
      <path
        d="M1905 385 c-19 -10 -31 -12 -33 -6 -2 6 -19 11 -38 11 l-34 0 0 -140
  0 -140 34 0 34 0 4 89 c4 107 20 134 77 129 48 -4 61 -32 61 -135 l0 -83 35 0
  35 0 0 110 c0 106 -1 111 -28 141 -34 38 -100 49 -147 24z"
      />
      <path
        d="M2841 383 c-54 -28 -76 -66 -76 -128 0 -83 45 -130 136 -142 42 -6
  129 21 129 40 0 6 -7 18 -16 28 -14 15 -18 16 -42 3 -15 -8 -39 -14 -53 -14
  -27 0 -79 34 -79 51 0 5 47 9 105 9 88 0 107 3 112 16 9 24 -28 104 -59 127
  -37 28 -114 33 -157 10z m119 -63 c33 -33 24 -40 -50 -40 -40 0 -70 4 -70 10
  0 21 41 50 70 50 17 0 39 -9 50 -20z"
      />
      <path
        d="M3150 384 c-44 -19 -43 -17 -29 -46 11 -25 11 -25 53 -10 36 13 48
  14 73 3 17 -7 33 -21 36 -30 4 -16 -5 -19 -66 -24 -80 -7 -117 -31 -117 -78 0
  -41 9 -58 41 -74 36 -19 82 -19 119 0 27 14 30 14 30 0 0 -11 10 -15 35 -15
  l35 0 0 108 c0 83 -4 113 -16 131 -34 48 -126 65 -194 35z m140 -169 c0 -23
  -37 -55 -64 -55 -23 0 -56 23 -56 39 0 19 28 31 72 31 36 0 48 -4 48 -15z"
      />
      <path
        d="M3784 358 c7 -18 35 -81 60 -140 25 -59 43 -113 40 -122 -8 -20 -39
  -29 -69 -22 -18 5 -27 1 -36 -16 -10 -18 -8 -24 7 -35 27 -20 97 -16 126 7 21
  16 111 205 164 345 6 14 1 16 -31 13 -38 -3 -39 -4 -74 -90 -20 -49 -39 -88
  -42 -88 -3 0 -21 39 -41 88 l-35 87 -42 3 -42 3 15 -33z"
      />
      <path
        d="M335 346 c-18 -27 -11 -52 17 -67 45 -24 91 24 63 67 -20 31 -59 32
  -80 0z"
      />
      <path
        d="M566 354 c-20 -19 -20 -34 0 -62 21 -30 59 -29 80 3 15 23 15 27 0
  50 -19 29 -56 33 -80 9z"
      />
      <path
        d="M450 320 c0 -5 18 -10 41 -10 24 0 38 4 34 10 -3 6 -22 10 -41 10
  -19 0 -34 -4 -34 -10z"
      />
      <path
        d="M60 300 c0 -8 4 -31 9 -50 6 -19 15 -54 21 -77 6 -24 15 -43 19 -43
  10 0 -27 160 -40 175 -6 5 -9 3 -9 -5z"
      />
      <path
        d="M242 196 c-34 -40 -59 -76 -56 -79 3 -3 32 25 63 61 65 77 76 92 63
  92 -5 0 -37 -33 -70 -74z"
      />
      <path d="M395 208 c8 -36 12 -44 20 -36 8 8 -7 68 -17 68 -6 0 -7 -14 -3 -32z" />
      <path
        d="M420 143 c-38 -15 -41 -66 -5 -89 23 -15 27 -15 50 0 32 21 33 59 3
  80 -24 17 -25 17 -48 9z"
      />
      <path
        d="M100 90 c-26 -26 -25 -36 4 -65 46 -46 108 5 72 60 -20 30 -49 32
  -76 5z"
      />
      <path
        d="M247 83 c-20 -2 -35 -8 -32 -14 7 -10 111 -1 135 12 16 9 -29 9 -103
  2z"
      />
    </g>
  </svg>
);

export default Logo;
