import axios, { AxiosResponse } from 'axios';
import { Container } from '@/features/templates/types';
import { AwardDefinition, AwardDefinitionDetailed } from '../types';

export class AwardDefinitionService {
  async getAwardDefinitions(): Promise<AwardDefinition[]> {
    const response = await axios.get(`/award_definitions`);

    return response.data;
  }

  async getAwardDefinition(
    awardDefinitionId: string
  ): Promise<AwardDefinitionDetailed> {
    const response = await axios.get(`/award_definitions/${awardDefinitionId}`);

    return response.data;
  }

  async editContainer(
    awardDefinitionId: number,
    containerId: string | number,
    name: string | undefined,
    description: string | undefined,
    dueDate: string | null
  ): Promise<Container> {
    const response: AxiosResponse<Container> = await axios.put(
      `/award_definitions/${awardDefinitionId}/container/${containerId}`,
      { name, description, dueDate }
    );

    return response.data;
  }
}
