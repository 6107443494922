import axios, { AxiosResponse } from 'axios';
import { ExpenditureReport, ExpenditureReportItemValue } from '../types';
import { Summary } from '@/features/documents/types';

function queryParamsFromSearchParams(searchParams: URLSearchParams) {
  const filterStartMonth = searchParams.get('start-month');
  const filterStartYear = searchParams.get('start-year');
  const filterEndMonth = searchParams.get('end-month');
  const filterEndYear = searchParams.get('end-year');
  const filterAggregatedMonths = searchParams.getAll('aggregated-months');

  const startPeriodSet = filterStartMonth != null && filterStartYear != null;
  const endPeriodSet = filterEndMonth != null && filterEndYear != null;
  const aggregationSet = filterAggregatedMonths != null;

  const anyParamSet = startPeriodSet || endPeriodSet || aggregationSet;

  const startPeriod = startPeriodSet
    ? `start_month=${filterStartMonth}&start_year=${filterStartYear}`
    : '';
  const endPeriod = endPeriodSet
    ? `${startPeriodSet ? '&' : ''}end_month=${filterEndMonth}&end_year=${filterEndYear}`
    : '';
  const aggregation = aggregationSet
    ? `${startPeriodSet || endPeriodSet ? '&' : ''}aggregated_months=${filterAggregatedMonths}`
    : '';

  return anyParamSet ? `?${startPeriod}${endPeriod}${aggregation}` : '';
}

export class ExpenditureReportService {
  async getExpenditureReport(
    spendPlanId: string,
    searchParams: URLSearchParams
  ): Promise<ExpenditureReport> {
    const queryParams = queryParamsFromSearchParams(searchParams);

    const response = await axios.get(
      `/spend_plans/${spendPlanId}/expenditure_report${queryParams}`
    );

    return response.data;
  }

  async deleteExpenditureReportItem(
    spendPlanId: string,
    expenditureReportItemId: number,
    searchParams: URLSearchParams
  ): Promise<ExpenditureReport> {
    const queryParams = queryParamsFromSearchParams(searchParams);

    const response = await axios.delete(
      `/spend_plans/${spendPlanId}/expenditure_report_items/${expenditureReportItemId}${queryParams}`
    );

    return response.data;
  }

  async addExpenditureReportItem(
    spendPlanId: string,
    month: number,
    year: number,
    searchParams: URLSearchParams
  ): Promise<ExpenditureReport> {
    const queryParams = queryParamsFromSearchParams(searchParams);

    const response = await axios.post(
      `/spend_plans/${spendPlanId}/expenditure_report_items${queryParams}`,
      { expenditure_report_item: { month, year } }
    );

    return response.data;
  }

  async editExpenditureReportItem(
    spendPlanId: string,
    expenditureReportItemId: number,
    month: number,
    year: number,
    completed: boolean,
    categoryValues: ExpenditureReportItemValue[],
    overrideDefaultIndirectCost: boolean,
    overriddenIndirectCost: number | undefined,
    comment: string | undefined,
    searchParams: URLSearchParams
  ): Promise<ExpenditureReport> {
    const queryParams = queryParamsFromSearchParams(searchParams);

    const response = await axios.put(
      `/spend_plans/${spendPlanId}/expenditure_report_items/${expenditureReportItemId}${queryParams}`,
      {
        expenditure_report_item: {
          month,
          year,
          completed,
          categoryValues,
          overrideDefaultIndirectCost,
          overriddenIndirectCost,
          comment,
        },
      }
    );

    return response.data;
  }

  async getExpenditureReportSummary(spendPlanId: string): Promise<Summary> {
    const response = await axios.get(
      `/spend_plans/${spendPlanId}/expenditure_report_summary`
    );

    return response.data;
  }

  async importExpenditureReportItems(
    spendPlanId: string,
    file: File,
    searchParams: URLSearchParams
  ): Promise<ExpenditureReport> {
    const queryParams = queryParamsFromSearchParams(searchParams);

    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(
      `spend_plans/${spendPlanId}/expenditure_report_items/import${queryParams}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }

  async getExport(
    spendPlanId: string,
    searchParams: URLSearchParams
  ): Promise<AxiosResponse<string>> {
    const queryParams = queryParamsFromSearchParams(searchParams);

    const response = await axios.get(
      `spend_plans/${spendPlanId}/expenditure_report_items/export${queryParams}`
    );

    return response;
  }
}
