import { AllocationAction } from '@/features/allocations/types';
import { BudgetCategory } from '@/features/budget-categories/types';
import { ReviewComment } from '@/features/comments/types';
import { AwardType, FundingType } from '@/features/awards/types';
import { StateVersion } from '@/features/versions/types';

export type TemplateVariant = 'Planning Guide' | 'Work Plan' | 'Spend Plan';

export type TemplateContainerTree = Template & {
  containers: Container[];
};

export interface Template {
  id: string;
  awardId: number;
  awardProgramId: string;
  awardDefinitionId: number;
  name: string;
  description?: string;
  fiscalYear: string;
  budgetPeriod?: number;
  awardDefinitionVersion: number;
  overdueDate?: string;
  documentsOverdueDate?: string;
  fundingType: FundingType;
  state: TemplateState;
  stateVersions?: StateVersion[];
  isOverdue: boolean;
  actions: TemplateAction[];
  allocationActions?: AllocationAction[];
  budgetCategories?: BudgetCategory[];
  topLevelContainerType?: ContainerType;
  nominalAllocation?: number;
  comments?: ReviewComment[];
  updatedAt: string;
  awardType: AwardType;
  fundingSourceId?: number;
}

export interface Container {
  id: string;
  checked: boolean;
  order: number;
  label?: string;
  sublabel?: string;
  name?: string;
  description?: string | undefined;
  descriptionLabel?: string;
  type: ContainerType;
  containers: Container[];
  parentId?: string;
  comments?: ReviewComment[];
  updatedAt?: string;
  dueDate?: string;
  awardContainerId?: number;
}

export interface ContainerCheckResponse {
  containers: { id: string; checked: boolean }[];
  formInputs: { id: string; checked: boolean }[];
}

export type ContainerType =
  | 'DOMAIN'
  | 'CAPABILITY'
  | 'FUNCTION'
  | 'OBJECTIVE'
  | 'OBJECTIVE_ORGANIZER'
  | 'ACTIVITY'
  | 'REQUIREMENT'
  | 'REQUIREMENT_ORGANIZER'
  | 'DEFINITION'
  | 'INSTRUCTION'
  | 'STRATEGY'
  | 'OUTCOME'
  | 'ACTIVITY_ORGANIZER'
  | 'GOAL';

export function isOrganizerContainer(container: Container) {
  return ['REQUIREMENT_ORGANIZER', 'OBJECTIVE_ORGANIZER'].includes(
    container.type
  );
}

export type TemplateState =
  | 'NEW'
  | 'IN_PROGRESS'
  | 'NEEDS_REVIEW'
  | 'IN_REVIEW'
  | 'RETURNED'
  | 'PUBLISHED'
  | 'ARCHIVED';

export type TemplateAction =
  | 'START'
  | 'EDIT'
  | 'SUBMIT'
  | 'START_REVIEW'
  | 'RETURN'
  | 'PUBLISH'
  | 'ARCHIVE';
