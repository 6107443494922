import axios from 'axios';
import { NotificationsData } from '../types';

export class NotificationsService {
  async getUserNotifications(
    unread: boolean,
    page: number
  ): Promise<NotificationsData> {
    const response = await axios.get('/notifications', {
      params: { page, unread },
    });

    return response.data;
  }

  async markNotificationAsRead(notificationId: number) {
    const response = await axios.put(`/notifications/${notificationId}`);
    return response.data;
  }
}
