import axios, { AxiosResponse } from 'axios';
import {
  Container,
  ContainerCheckResponse,
  Template,
  TemplateContainerTree,
} from '@/features/templates/types';
import { ContainerQuestion } from '@/features/documents/types';

export abstract class TemplateService {
  basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  async getTemplatesByAwardProgramId(
    awardProgramId: string
  ): Promise<Template[]> {
    const response = await axios.get(this.basePath, {
      params: { awardProgramId },
    });

    return response.data;
  }

  async getTemplate(id: string): Promise<TemplateContainerTree> {
    const response = await axios.get(`${this.basePath}/${id}`);

    return response.data;
  }

  async getPreview(id: string): Promise<TemplateContainerTree> {
    const response = await axios.get(`${this.basePath}/${id}/preview`);

    return response.data;
  }

  async getQuestions(id: string): Promise<ContainerQuestion[]> {
    const response = await axios.get(`${this.basePath}/${id}/questions`);

    return response.data;
  }

  async check(
    templateId: string,
    id: string | number,
    checked: boolean,
    type: 'budget_category' | 'container' | 'form_input'
  ): Promise<ContainerCheckResponse> {
    const body = {
      checked,
    };

    body[`${type}_id`] = id;

    const response = await axios.patch(
      `${this.basePath}/${templateId}/mark_checked`,
      body
    );

    return response.data;
  }

  async editContainer(
    templateId: string,
    containerId: string | number,
    name: string | undefined,
    description: string | undefined,
    dueDate: string | null
  ): Promise<Container> {
    const response: AxiosResponse<Container> = await axios.put(
      `${this.basePath}/${templateId}/container/${containerId}`,
      { name, description, dueDate }
    );

    return response.data;
  }

  async addContainerComment(
    templateId: string,
    containerId: string | number,
    content = ''
  ): Promise<Container> {
    const response = await axios.post(
      `${this.basePath}/${templateId}/container/${containerId}/comment`,
      { comment: { content } }
    );

    return response.data;
  }

  async start(
    templateId: string,
    preInitFromTemplateId?: string | null
  ): Promise<TemplateContainerTree> {
    const response = await axios.post(`${this.basePath}/${templateId}/start`, {
      preInitFromTemplateId,
    });

    return response.data;
  }

  async submit(id: string): Promise<Template> {
    const response = await axios.post(`${this.basePath}/${id}/submit`);

    return response.data;
  }

  async startReview(id: string): Promise<Template> {
    const response = await axios.post(`${this.basePath}/${id}/review`);

    return response.data;
  }

  async return(id: string, explanation: string): Promise<Template> {
    const response = await axios.post(`${this.basePath}/${id}/return`, {
      explanation,
    });

    return response.data;
  }

  async publish(id: string): Promise<Template> {
    const response = await axios.post(`${this.basePath}/${id}/publish`);

    return response.data;
  }

  async updateOverdueDates(
    templateId: string,
    overdueDate?: string,
    documentsOverdueDate?: string
  ): Promise<Template> {
    const response = await axios.put(
      `${this.basePath}/${templateId}/update_overdue_dates`,
      {
        overdueDate,
        documentsOverdueDate,
      }
    );

    return response.data;
  }
}
